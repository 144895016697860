import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./screens/Login/LoginPage";
import CreateMemberPage from "./screens/CreateMember/CreateMemberPage";
import ListMembersPage from "./screens/ListMembers/ListMembersPage";
import ReportDirectoryPage from "./screens/Reports/ReportDirectoryPage";
import MemberDetailsPage from "./screens/Reports/MemberDetailsPage";
import DistributionsPage from "./screens/Reports/DistributionsPage";
import UnwantedStatsPage from "./screens/Reports/UnwantedStatsPage";
import WardPage from "./screens/Ward/Ward";
import TargetsPage from "./screens/Targets/Targets";
import AddAccount from "./screens/AddAccount/AddAccount";
import RaceDemographicsPage from "./screens/Demographics/RaceDemographicsPage";
import GenderDemographicsPage from "./screens/Demographics/GenderDemographicsPage";
import AgeDemographicsPage from "./screens/Demographics/AgeDemographicsPage";
import LanguageDemographicsPage from "./screens/Demographics/LanguageDemographicsPage";
// import { PrivateRoute } from "./hooks/Auth/useAuth";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/targets" element={<TargetsPage />} />
        <Route path="/create-member" element={<CreateMemberPage />} />
        <Route path="/members-list" element={<MemberDetailsPage />} />
        <Route path="/reports" element={<ReportDirectoryPage />} />
        <Route path="/member-details" element={<MemberDetailsPage />} />
        <Route path="/distributions" element={<DistributionsPage />} />
        <Route path="/age-demographics" element={<AgeDemographicsPage />} />
        <Route path="/race-demographics" element={<RaceDemographicsPage />} />
        <Route
          path="/gender-demographics"
          element={<GenderDemographicsPage />}
        />
        <Route
          path="/language-demographics"
          element={<LanguageDemographicsPage />}
        />
        <Route path="/unwanted-stats" element={<UnwantedStatsPage />} />
        <Route path="/add-account" element={<AddAccount />} />
        <Route path="/wards" element={<WardPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
